@import '../headings/headings.scss';

.navbar {
    background-color: #27313B;
    display: flex;
    flex-direction: row;
    max-height: 10vh;
}

.logo-button {
    background: none;
    border: none;
    margin-top: 25px;
    margin-bottom: 25px;
    margin-left: 25px;
    margin-right: auto;
    cursor: pointer;
}

.navbar-logo {
    padding: none;
}

.menu-button {
    border: none;
    background: none;
    margin-top: 31px;
    margin-left: auto;
    margin-right: 25px;
    margin-bottom: 25px;
    cursor: pointer;
}

.menu-image {
    padding: auto;
    margin: auto;
}

@media not all and (min-resolution:.001dpcm) { 
    @supports (-webkit-appearance:none) {
        .menu-image { 
            transform: scale(0.8,0.8); 
        }
    }
}

@media screen and (max-width: 600px){
    .navbar {
        position: fixed;
        z-index: 10;
        top: 0;
        width: 100%;
        // background-color: #27313B;
        // background-color: transparent;
        background-color: #27313bdc;
    }

    .sticky {
        padding-top: 10.5vh;
    }
    
    @supports (-webkit-touch-callout: none) {
        // .menu-button{
        //     margin-top: 37px !important;
        // }
        .menu-image{
            // margin-top: -20px !important;
            // padding-top: 10px !important;
            transform: scale(0.8,0.8);
        }
    }
}
