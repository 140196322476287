html, body{
    margin:0;
    padding:0;
    overflow-x:hidden;
    scrollbar-color: transparent transparent;
}


:root {
    --bg-colour: #27313B;
    --gradient-1: linear-gradient(180deg, #FF76A5 0%, #C067E2 100%);
    --gradient-2: linear-gradient(122.18deg, #C067E2 13.81%, #749BFF 85.95%);
    --gradient-3: linear-gradient(230.6deg, #FF76A5 13.56%, #FE8C5A 87.82%);
    --gradient-4: linear-gradient(242.5deg, #FE8C5A 25.46%, #F7BF00 89.55%);
    --gradient-5: linear-gradient(116.42deg, #749BFF 22.88%, #FFCD20 91.67%);
    --blue: #749BFF;
    --purple: #C067E2;
    --pink: #FF76A5;
    --orange: #FE8C5A;
    --yellow: #FFCE1F;
    --main-text: #fff;
}


.insyder--window{
    display: flex;
    justify-content: center;
}

.insyder--title{
    color: #fff;
}


.insyder--box{
    display: flex;
    justify-content: center;
    margin-left: 10%;
    margin-right: 10%;
}

@media only screen and (max-width: 2000px){
    .insyder--title{
        margin-left: 15% !important;
    }
}



@media only screen and (max-width: 1600px){
    .insyder--title{
        margin-left: 15% !important;
    }
}

@media only screen and (max-width: 1360px){
    .insyder--title{
        margin-left: 15% !important;
    
    }
}

@media only screen and (max-width: 1260px){
   
    .insyder--title{
        margin-left: 15% !important;
    }
}

@media only screen and (max-width: 1165px){
    .insyder--title{
        margin-left: 15% !important;
    }
}

@media only screen and (max-width: 1065px){
    .insyder--title{
        margin-left: 15% !important;
    }
}

@media only screen and (max-width: 850px){
    .insyder--title{
        margin-left: 10% !important;
    }
}


::-webkit-scrollbar {
    display: none;
  }

.image-list-item > img{
    max-width: 100% !important;
    height: auto !important;
}

.insyderImageOverlay{
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.6);
    color: #ffffff;
    opacity: 0;
}

.insyderImageOverlay:hover{
    opacity: 1;
}
.textOnTop{
        display: block !important;
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        letter-spacing: 5%;
        font-family: 'Nunito Sans';
        font-style: normal;
        font-weight: 400;
        font-size: 2.4vh;
        line-height: 3.2vh;
        color: #FFFFFF;
}



.circle1IP {
    position: absolute;
    background-size: cover;
    max-width: 60%;
    margin-top: 0vh;
    filter: blur(8px);
    -webkit-filter: blur(80px);
    z-index: 0;
    left: 0%;
}

.circle2IP {
    position: absolute;
    background-size: cover;
    margin-top: -20vh;
    filter: blur(8px);
    -webkit-filter: blur(80px);
    z-index: 0;
    right: 0%;
    top: 200%;
}

.circle3IP {
    position: absolute;
    background-size: cover;
    max-width: 110%;
    margin-top: 60vh;
    left: -10%;
    filter: blur(8px);
    -webkit-filter: blur(80px);
    z-index: 0;
    top: 300%;
}

.circle4IP{
    position: absolute;
    background-size: cover;
    filter: blur(8px);
    -webkit-filter: blur(80px);
    right: 0%;
    top: 500%;
}