:root {
    --bg-colour: #27313B;
    --main-gradient: radial-gradient(circle, rgba(238, 174, 202, 1) 0%, rgba(148, 187, 233, 1) 100%);
    --gradient-1: linear-gradient(180deg, #FF76A5 0%, #C067E2 100%);
    --gradient-2: radial-gradient(80.69% 208.78% at 108.28% 112.58%, #EABFFF 0%, rgba(135, 38, 183, 0) 100%),
        radial-gradient(80.38% 222.5% at -13.75% -12.36%, #749BFF 0%, rgba(255, 255, 255, 0) 100%);
}

* {
    box-sizing: border-box;
}

html {
    scroll-behavior: smooth;
}

.circleOne {
    position: absolute;
    background-size: cover;
    max-width: 60%;
    margin-top: 0vh;
    filter: blur(8px);
    -webkit-filter: blur(80px);
    z-index: 0;
}

.circleTwo {
    position: absolute;
    background-size: cover;
    margin-top: -30vh;
    filter: blur(8px);
    -webkit-filter: blur(80px);
    z-index: 0;
}

.circleThree {
    position: absolute;
    background-size: cover;
    max-width: 110%;
    margin-top: -20vh;
    left: -40%;
    filter: blur(8px);
    -webkit-filter: blur(80px);
    z-index: 0;
}


.headingRight {
    color: #FFFFFF;
    margin-top: 1.65em;
    margin-right: 20em;
    position: absolute;
    left: 120%;
    top: 15%;
}

.headingLeft {
    color: #FFFFFF;
    margin-top: 1.65em;
    margin-right: 20em;
    position: absolute;
    right: 40%;
    top: 15%;
}

.headingLeftDec {
    color: #FFFFFF;
    margin-top: 1.65em;
    margin-right: 20em;
    position: absolute;
    right: 40%;
    top: 15%;
}

.buttons {
    right: 14%;
    position: absolute;
    top: 24vh;
    display: flex;
    gap: 13px;
}

.textButton {
    font-family: 'Nunito Sans';
    background-color: Transparent;
    background-repeat: no-repeat;
    border: none;
    color: #a5a5a5;
    font-size: 2.1vh;
    cursor: pointer;
    z-index: 1;
}

/* The actual timeline (the vertical ruler) */
.timeline {
    position: relative;
    max-width: 1200px;
    margin: 0 auto;
}

/* The actual timeline (the vertical ruler) */
.timeline::after {
    content: '';
    position: absolute;
    width: 1rem;
    background-image: radial-gradient(80.69% 208.78% at 108.28% 112.58%, #EABFFF 0%, rgba(135, 38, 183, 0) 100%),
        radial-gradient(80.38% 222.5% at -13.75% -12.36%, #749BFF 0%, rgba(255, 255, 255, 0) 100%);
    top: 0;
    bottom: 0;
    left: 49.6%;
    margin-left: -3px;
    border-radius: 10px;
}

/* Container around content */
.container {
    padding: 10px 40px;
    position: relative;
    background-color: inherit;
    width: 50%;
}

/* The circles on the timeline */
.container::after {
    content: '';
    position: absolute;
    width: 25px;
    height: 25px;
    right: -17px;
    background: radial-gradient(80.69% 208.78% at 108.28% 112.58%, #EABFFF 0%, rgb(77, 109, 156) 100%),
        radial-gradient(80.38% 222.5% at -13.75% -12.36%, #5c82e4 0%, rgb(230, 207, 207) 100%);
    border: 4px solid #749BFF;
    top: 150px;
    border-radius: 50%;
    z-index: 1;
}

/* Place the container to the left */
.leftGallery {
    left: 0;
}

/* Place the container to the right */
.rightGallery {
    left: 50%;
}

/* Fix the circle for containers on the right side */
.rightGallery::after {
    left: -16px;
}

@media screen and (min-width: 2000px) {
    .circleOne {
        width: 100vw;
        height: 100vh;
        margin-left: 10%;
    }

    .circleTwo {
        width: 80vw;
        height: 80vh;
        margin-top: -1rem;
        margin-left: -20rem;
    }

    .circleThree {
        width: 60vw;
        height: 60vh;
    }
}

@media screen and (max-width: 1430px) {
    .circleOne {
        width: 50vw;
        height: 50vh;
        margin-left: 10%;
    }

    .circleTwo {
        width: 70vw;
        height: 70vh;
        margin-top: -8rem;
        margin-left: -10rem;
    }

    .circleThree {
        width: 60vw;
        height: 60vh;
    }
}

/* Media queries - Responsive timeline on screens less than 600px wide */
@media screen and (max-width: 600px) {

    :root {
        h1.gallery {
            margin: 40px 30px 160px !important;
        }
    }

    /* Place the timelime to the left */
    .timeline::after {
        left: 31px;
    }

    /* Full-width containers */
    .container {
        width: 100%;
        padding-left: 70px;
        padding-right: 40px;
    }

    .buttons {
        right: 15rem;
        padding-left: 7%;
        padding-bottom: 13%;
        position: static;
        top: 30%;
        display: flex;
        gap: 13px;
    }

    /* Make sure all circles are at the same spot */
    .leftGallery::after,
    .rightGallery::after {
        left: 18px;
    }

    /* Make all right containers behave like the left ones */
    .rightGallery {
        left: 0%;
    }

    .headingRight {
        margin-top: 1.65em;
        margin-right: 1em;
        position: absolute;
        left: 3em;
        top: 15%;
    }

    .headingLeft {
        margin-top: 1.65em;
        margin-right: 114%;
        position: absolute;
        right: -12.1em;
        top: 15%;
    }

    .headingLeftDec {
        margin-top: 1.65em;
        margin-right: 108%;
        position: absolute;
        right: -12.1em;
        top: 15%;
    }

    .circleOne {
        width: 50vw;
        height: 50vh;
        margin-left: 10%;
    }

    .circleTwo {
        width: 70vw;
        height: 70vh;
        margin-top: -8rem;
    }

    .circleThree {
        width: 60vw;
        height: 60vh;
    }
}

@media only screen and (max-width:1590px) {
    :root {
        h1.gallery {
            margin: 80px 6% !important; //!! I changed your responsiveness a little as it was messing with mine on the profile pages. Now your changes only apply to your title, and overwrite mine for your page (hence !important)
            position: static !important;
        }
    }
}


@media screen and (min-width: 768px) and (max-width: 1023px) {
    .headingLeft {
        margin-top: 1.65em;
        margin-right: 114%;
        position: absolute;
        right: 1em;
        top: 15%;
    }

    .headingLeftDec {
        margin-top: 1.65em;
        margin-right: 108%;
        position: absolute;
        right: 1em;
        top: 15%;
    }
}