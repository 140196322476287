@import url('https://fonts.googleapis.com/css2?family=Lexend&display=swap');
// @import url('https://fonts.googleapis.com/css2?family=Lexend&family=Montserrat&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Nunito+Sans&display=swap');

:root {
    --bg-colour: #27313B;
    --gradient-1: linear-gradient(180deg, #FF76A5 0%, #C067E2 100%);
    --gradient-2: linear-gradient(122.18deg, #C067E2 13.81%, #749BFF 85.95%);
    --gradient-3: linear-gradient(230.6deg, #FF76A5 13.56%, #FE8C5A 87.82%);
    --gradient-4: linear-gradient(242.5deg, #FE8C5A 25.46%, #F7BF00 89.55%);
    --gradient-5: linear-gradient(116.42deg, #749BFF 22.88%, #FFCD20 91.67%);
    --blue: #749BFF;
    --purple: #C067E2;
    --pink: #FF76A5;
    --orange: #FE8C5A;
    --yellow: #FFCE1F;
    --main-text: #fff;

    font-size: 18px;
    line-height: 22px;
    font-family: 'Nunito Sans';
    font-style: normal;

    scroll-behavior: smooth;
    background-color: var(--bg-colour);
}

html,
body {
    margin: 0;
    padding: 0;
    color: var(--main-text);
}

h1 {
    font-family: 'Lexend';
    font-style: normal;
    font-weight: 600;
    font-size: 34px;
    line-height: 42px;
    letter-spacing: -0.01em;
    /*Background will depend on which section*/
}

h2 {
    font-family: 'Lexend';
    font-style: normal;
    font-weight: 600;
    font-size: 30px;
    line-height: 38px;
    letter-spacing: -0.01em;
    color: #000000;
}

h3 {
    font-family: 'Lexend';
    font-style: normal;
    font-weight: 600;
    font-size: 24px;
    line-height: 30px;
    letter-spacing: -0.01em;
    color: #000000;
}

h4 {
    font-family: 'Lexend';
    font-style: normal;
    font-weight: 600;
    font-size: 18px;
    line-height: 22px;
    letter-spacing: -0.01em;
    color: #000000;
}

p {
    font-family: 'Nunito Sans';
    // font-style: normal;
    // font-weight: 400;
    // font-size: 17px;
    // line-height: 23px;
}

a {
    font-family: 'Nunito Sans';
    font-style: normal;
    font-weight: 400;
    font-size: 18px;
    line-height: 22px;
    text-decoration-line: underline;
    color: rgba(255, 255, 255, 0.9);
}

caption {
    font-family: 'Nunito Sans';
    font-style: normal;
    font-weight: 400;
    font-size: 18px;
    line-height: 22px;
    color: #fff;
}

@media screen and (max-width: 1600px) {
    :root {
        font-size: 18px;
    }
}

@media screen and (max-width: 1400px) {
    :root {
        font-size: 18px;
    }
}

@media screen and (max-width: 1200px) {
    :root {
        font-size: 17px;
    }
}

@media screen and (max-width: 960px) {
    :root {
        font-size: 15px;
    }
}

@media screen and (max-width: 480px) {
    :root {
        font-size: 13px;
    }
}

@media screen and (max-width: 360px) {
    :root {
        font-size: 11px;
    }
}