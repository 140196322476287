.aboutAvatar {
    background-color: var(--bg-colour);
}

.wrapper {
    border: 0px hidden transparent;
    border-radius: 50%;
    padding: 2px;
    margin: 0px;
    background-image: radial-gradient(80.69% 208.78% at 108.28% 112.58%, #EABFFF 0%, rgba(135, 38, 183, 0) 100%),
    radial-gradient(80.38% 222.5% at -13.75% -12.36%, #749BFF 0%, rgba(255, 255, 255, 0) 100%);
    width: 90px;
    height: 90px;
    display: inline-block;
}

.frame {
    margin-right: 34px;
    margin-bottom: 10px;
    display: inline-block;
}

@media screen and (max-width: 960px) {
    .frame {
        margin-right: 18px;
    }
}