// from global 
@import url('https://fonts.googleapis.com/css2?family=Lexend&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Lexend&family=Montserrat&display=swap');

html,
body {
    margin: 0;
    padding: 0;
    color: var(--main-text);
}

h1 {
    font-family: 'Lexend';
    font-style: normal;
    font-weight: 600;
    font-size: 2.125rem;
    line-height: 2.625rem;
    letter-spacing: -0.01em;
    margin: 5rem 15% 3rem 15%;
    /*Background will depend on which section*/
}

h2 {
    font-family: 'Lexend';
    font-style: normal;
    font-weight: 600;
    font-size: 1.875rem;
    line-height: 38px;
    letter-spacing: -0.01em;
    color: #000000;
}

h4 {
    font-family: 'Lexend';
    font-style: normal;
    font-weight: 600;
    font-size: 1.125rem;
    line-height: 22px;
    letter-spacing: -0.01em;
    color: #000000;
}

a {
    font-family: 'Nunito Sans';
    font-style: normal;
    font-weight: 400;
    font-size: 1.125rem;
    line-height: 22px;
    text-decoration-line: underline;
    color: rgba(255, 255, 255, 0.9);
}

caption {
    font-family: 'Proxima Nova';
    font-style: normal;
    font-weight: 400;
    font-size: 1.125rem;
    line-height: 22px;
    color: #fff;
}

//
.graphDescription {
    margin: 0px 15% 2rem 15%
}

h3.sectionHeader {
    //padding-bottom: 0.66em; //This might mess with the spacing
    color: #fff;
    font-family: 'Lexend';
    font-style: normal;
    font-weight: 600;
    font-size: 1.5rem;
    line-height: 30px;
    letter-spacing: -0.01em;
    margin: 0px 15% 1rem 15%
}

h3.Title {
    font-family: 'Lexend';
    font-style: normal;
    font-weight: 600;
    font-size: 19.5px;
    line-height: 30px;
    letter-spacing: -0.01em;
    color: #000000;
}

h1.demographics {
    background: linear-gradient(90deg, var(--pink) 0%, var(--purple) 100%);
    background-clip: text;
    color: transparent;
    width: max-content;
    white-space: nowrap;
}

h1.lifestyle {
    background: linear-gradient(109.91deg, var(--blue) 22.98%, #FFCD20 93.33%);
    background-clip: text;
    color: transparent;
    width: max-content;
    white-space: nowrap;
}

h1.coop {
    background: linear-gradient(251.34deg, var(--orange) 9.78%, #F7BF00 84.54%);
    background-clip: text;
    color: transparent;
    width: max-content;
    white-space: nowrap;
}

h1.academics {
    background: linear-gradient(245.1deg, var(--pink) 5.86%, var(--orange) 74.16%);
    background-clip: text;
    color: transparent;
    width: max-content;
    white-space: nowrap;
}

h1.admissions {
    background: linear-gradient(114.13deg, var(--purple) 24.52%, var(--blue) 93.58%);
    background-clip: text;
    color: transparent;
    width: max-content;
    white-space: nowrap;
}

@media screen and (max-width: 1600px) {
    .graphDescription {
        margin: 0px 15% 2rem 15%
    }

    h3.sectionHeader {
        margin: 0px 15% 1rem 15%
    }

    h1 {
        margin-left: 15%;
        margin-right: 15%;
    }
}

@media screen and (max-width: 1400px) {
    .graphDescription {
        margin: 0px 15% 2rem 15%
    }

    h3.sectionHeader {
        margin: 0px 15% 1rem 15%
    }

    h1 {
        margin-left: 15%;
        margin-right: 15%;
    }
}

@media screen and (max-width: 1200px) {
    .graphDescription {
        margin: 0px 15% 2rem 15%
    }

    h3.sectionHeader {
        margin: 0px 15% 1rem 15%
    }

    h1 {
        margin-left: 15%;
        margin-right: 15%;
    }
}

@media screen and (max-width: 960px) {
    .graphDescription {
        margin: 0px 10% 2rem 10%
    }

    h3.sectionHeader {
        margin: 0px 10% 1rem 10%
    }

    h1 {
        margin-left: 10%;
        margin-right: 10%;
    }
}

@media screen and (max-width: 480px) {
    .graphDescription {
        margin: 0px 10% 2rem 10%
    }

    h3.sectionHeader {
        margin: 0px 10% 1rem 10%
    }

    h1 {
        margin-left: 10%;
        margin-right: 10%;
    }
}

@media screen and (max-width: 360px) {
    .graphDescription {
        margin: 0px 10% 2rem 10%
    }

    h3.sectionHeader {
        margin: 0px 10% 1rem 10%
    }

    h1 {
        margin-left: 10%;
        margin-right: 10%;
    }
}