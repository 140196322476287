.container-slider {
    max-width: 1200px;
    height: 600px;
    margin: 100px auto 0;
    position: relative;
    overflow: hidden;
}

.slide {
    width: 100%;
    height: 100%;
    position: absolute;
    opacity: 0;
    transition: opacity ease-in-out 0.4s;
    border-radius: 5px;
}

.slide img {
    width: 100%;
    height: 100%;
    object-fit: scale-down;
}

.active-anim {
    opacity: 1;
}

.btn-slide-photo {
    width: 60px;
    height: 60px;
    border-radius: 50%;
    background: none;
    border: none;
    position: absolute;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
}

.btn-slide img {
    width: 30px;
    height: 30px;
    pointer-events: none;
}

.prev {
    top: 50%;
    left: -5px;
    transform: translateY(-60%);
}

.next {
    top: 50%;
    right: -5px;
    transform: translateY(-60%);
}

.container-dots-photo {
    position: absolute;
    bottom: 20px;
    left: 50%;
    transform: translateX(-50%);
    display: flex;
    flex-wrap: wrap;
    cursor: pointer;
}

.dot {
    width: 1.2vh;
    height: 1.2vh;
    border-radius: 50%;
    margin: 0 3.5px 10px;
    background: #616161;
}

.dot.active {
    background: #FFFFFF;
}

@media screen and (max-width: 700px) {
    .container-slider {
        margin: 80px 5px 0;
    }

    .container-dots-photo {
        position: absolute;
        bottom: 60px;
        left: 20%;
        transform: translateX(-10%);
        display: flex;
        flex-wrap: wrap;
    }
}