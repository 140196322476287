@import url('https://fonts.googleapis.com/css2?family=Lexend&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Lexend&family=Montserrat&display=swap');

.heart {
  height: 0.8em;
}

.arrow {
  height: 1.3rem;
}

.Footer {
  border: none;
  color: #fff;
  margin: 0px auto 2.5rem auto;
  width: 70%;
  text-align: center;
  font-size: 15px;
}

.ScrollWrapper {
  border: 0px hidden transparent;
  border-radius: 50px;
  padding: 3px; //keep as px
  margin: 0px
}

.CreatedBy p {
  margin: 0px;
  padding: 0px;
}

#ScrollToTop {
  background-image: linear-gradient(135deg, rgba(118, 151, 254, 1) 0%, rgba(245, 132, 182, 1) 46%, rgba(252, 168, 62, 1) 87%, rgba(254, 201, 32, 1) 100%);
  display: inline-block;
  position: fixed;
  opacity: 0.5;
  right: 1.5625rem;
  bottom: 2.5rem
}