@import 'styles/globals.scss';

.insyder-overlay {
    position: fixed;
    left: 0;
    top: 0;
    right: 0;
    bottom: 0;
    background-color: transparent;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    backdrop-filter: blur(200px);
    -webkit-backdrop-filter: blur(200px);
    @-moz-document url-prefix() {
        background-color: #27313B;
    }
    z-index: 30;
}

.close-button {
    border: none;
    background: none;
    margin-top: 2em;
    margin-left: auto;
    margin-right: 40px;
    margin-bottom: auto;
    max-height: 40px;
    max-width: 40px;
    padding: none;
}

.close-button:hover {
    opacity: 0.5;
}

.close-icon {
    padding: none;
    margin: none;
}

.insyder-overlay-content {
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-evenly;
}

.lock-icon {
    margin-bottom: 1vh;
}

.main-text {
    color: #fff;
    margin-top: auto;
    margin-bottom: 1vh;
    font-weight: 500;
    text-align: center;
}

.sub-text {
    color: #fff;
    opacity: 0.5;
    margin-top: 0;
    margin-bottom: 7.5%;
    font-weight: 100;
    text-align: center;
}

.password-field {
    color: white;
    text-align: center;
    font-size: 1.8vw;
    border-style: normal;
    border: double 0.2px transparent;
    border-radius: 10px;
    background-image: linear-gradient(#27313B, #27313B), linear-gradient(122.18deg, #749BFF 13.81%, #C067E2 85.95%);
    background-origin: border-box;
    background-clip: content-box, border-box;
    margin-top: -20px;
    height: 5vh;
    width: 50%;
}

.password-field:focus { 
    outline: none !important;
    border-color: #719ece42;
    box-shadow: 0 0 10px #719ece42;
 }

@media screen and (max-width: 600px){
    .password-field{
        font-size: 6vw;
    }

    .main-text {
        font-size: 4vw;
    }
    .password-field {
        margin-top: 0;
        height: 5vh;
        width: 80%;
        border-width: 0.01em;
    }
    .password-field:focus{
        border-width: 0.01em;
    }

    @supports (-webkit-touch-callout: none) {
        .password-field {
            color: white;
            text-align: center;
            font-size: 2vw;
            border-style: normal;
            border: double 0.2px transparent;
            border-radius: 10px;
            background-image: linear-gradient(#27313B, #27313B), linear-gradient(122.18deg, #749BFF 13.81%, #C067E2 85.95%);
            background-origin: border-box;
            background-clip: content-box, border-box;
            margin-top: 0px;
            height: 5vh;
            width: 50%;
        }
    }

    .lock-icon {
        width: 20%;
    }

    .sub-text {
        margin-right: -30%;
        margin-left: -30%;
    }
}

@media screen and (max-width: 600px){
    @supports not (-webkit-touch-callout: none) {
        .insyder-overlay{
            background-color: #27313B;
        }
    }
}