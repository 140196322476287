canvas {
  margin: 0;
  display: inline-block !important;
}

.chart {
  // position: relative;
  // height: 100%;
  // width: 100% !important;
  // flex-grow: 1 !important;
  // flex-basis: 1 !important
  // width: 26rem;
  // height: 13rem
  // height: 100%
  width: 100%;
  display: inline-block !important;
  margin-top: 0px;
  margin-bottom: 64px;
}

.left {
  display: inline-block;
  width: 33% !important;
  padding-right: 2%;
  margin-left: 15%;
}

.right {
  display: inline-block;
  width: 33% !important;
  padding-left: 2%;
  margin-right: 15%;
}

.center {
  width: 34% !important;
  margin-left: 33%;
  margin-right: 33%;
  display: inline-block;
}

@media screen and (max-width: 1600px) {
  .right {
    width: 33% !important;
    //padding-left: 2%;
    margin-right: 15%;
  }

  .left {
    width: 33% !important;
    //padding-right: 2%;
    margin-left: 15%;
  }

  .center {
    width: 34% !important;
    margin-left: 33%;
    margin-right: 33%;
  }
}

@media screen and (max-width: 1400px) {

  .right {
    width: 33% !important;
    //padding-left: 2%;
    margin-right: 15%;
  }

  .left {
    width: 33% !important;
    //padding-right: 2%;
    margin-left: 15%;
  }

  .center {
    width: 50% !important;
    margin-left: 25%;
    margin-right: 25%;
  }
}

@media screen and (max-width: 1200px) {

  .right {
    width: 33% !important;
    //padding-left: 2%;
    margin-right: 15%;
  }

  .left {
    width: 33% !important;
    //padding-right: 2%;
    margin-left: 15%;
  }

  .center {
    width: 70% !important;
    margin-left: 15%;
    margin-right: 15%;
  }

}

@media screen and (max-width: 960px) {

  .right {
    width: 90% !important;
    margin-left: 5%;
    margin-right: 5%;
  }

  .left {
    width: 90% !important;
    margin-left: 5%;
    margin-right: 5%;
  }

  .center {
    width: 90% !important;
    margin-left: 5%;
    margin-right: 5%;
  }

}

@media screen and (max-width: 480px) {

  .center {
    width: 90% !important;
    margin-left: 5%;
    margin-right: 5%;
  }

  .center {
    width: 90% !important;
    margin-left: 5%;
    margin-right: 5%;
  }

  .center {
    width: 90% !important;
    margin-left: 5%;
    margin-right: 5%;
  }

}

@media screen and (max-width: 360px) {

  .center {
    width: 90% !important;
    margin-left: 5%;
    margin-right: 5%;
  }

  .center {
    width: 90% !important;
    margin-left: 5%;
    margin-right: 5%;
  }

  .center {
    width: 90% !important;
    margin-left: 5%;
    margin-right: 5%;
  }
}