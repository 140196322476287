.tiles {
  background-image: linear-gradient(122.18deg, rgba(192, 103, 226, 0.3) 13.81%, rgba(116, 155, 255, 0.3) 85.95%);
  border: 0 solid transparent;
  border-radius: 1.25rem;
  padding: 1.5625rem;
  margin: 0 15% 1rem 15%;
  text-align: center;
}

.spacer {
  margin: 0 auto 4rem auto;
}

@media screen and (max-width: 1600px) {
  .tiles {
    margin: 0 15% 1rem 15%;
  }

}

@media screen and (max-width: 1400px) {
  .tiles {
    margin: 0 15% 1rem 15%;
  }
}

@media screen and (max-width: 1200px) {
  .tiles {
    margin: 0 15% 1rem 15%;
  }
}

@media screen and (max-width: 960px) {
  .tiles {
    margin: 0 10% 1rem 10%;
  }
}

@media screen and (max-width: 480px) {
  .tiles {
    margin: 0 10% 1rem 10%;
  }
}

@media screen and (max-width: 360px) {
  .tiles {
    margin: 0 10% 1rem 10%;
  }
}