:root {
    --bg-colour: #27313B;
    --gradient-1: linear-gradient(180deg, #FF76A5 0%, #C067E2 100%);
    --gradient-2: linear-gradient(122.18deg, #C067E2 13.81%, #749BFF 85.95%);
    --gradient-3: linear-gradient(230.6deg, #FF76A5 13.56%, #FE8C5A 87.82%);
    --gradient-4: linear-gradient(242.5deg, #FE8C5A 25.46%, #F7BF00 89.55%);
    --gradient-5: linear-gradient(116.42deg, #749BFF 22.88%, #FFCD20 91.67%);
    --blue: #749BFF;
    --purple: #C067E2;
    --pink: #FF76A5;
    --orange: #FE8C5A;
    --yellow: #FFCE1F;
    --main-text: #fff;

    line-height: 22px;
    font-family: 'Nunito Sans';
    font-style: normal;

    scroll-behavior: smooth;
    background-color: var(--bg-colour);
}

.btn-slide {
    width: 60px;
    height: 60px;
    border-radius: 50%;
    background: none;
    border: none;
    position: absolute;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
}

.prev {
    top: 50%;
    left: -5px;
    transform: translateY(-60%);
}

.next {
    top: 50%;
    right: -5px;
    transform: translateY(-60%);
}

.container-dots {
    position: absolute;
    bottom: 20px;
    left: 50%;
    transform: translateX(-50%);
    display: flex;
    flex-wrap: wrap;
    cursor: pointer;
}

.dot {
    width: 1.2vh;
    height: 1.2vh;
    border-radius: 50%;
    margin: 0 3.5px 10px;
    background: #616161;
}

.dot.active {
    background: #FFFFFF;
}

.UltimateWrapper {
    max-height: 90vh;
    overflow: hidden;
}

.InstructionWrapper {
    display: flex;
    flex-direction: row;
    justify-content: center;
    gap: 10px;
    color: #A9ADB1;
    font-size: 16px;
    animation: bounce 1.5s;
    animation-direction: alternate;
    animation-iteration-count: infinite
}

@keyframes bounce {
    from {
        transform: translate3d(0, 0, 0);
    }
    to {
        transform: translate3d(0, 18px, 0);
    }
}

.Wrapper {
    display: flex;
    flex-direction: row;
    justify-content: center;
    margin-left: 1.5vw;
}

.CircleWrapper {
    display: flex;
    flex-direction: column;
    width: 310px;
    height: 310px;
    border-radius: 50%;
    justify-content: center;
}

.CoopGradient,
.CoopChange {
    display: flex;
    background: linear-gradient(242.5deg, #FE8C5A 25.46%, #F7BF00 89.55%);
    ;
    mix-blend-mode: normal;
    width: 290px;
    height: 290px;
    border-radius: 50%;
    justify-content: center;
}

.AcademicsGradient,
.AcademicsChange {
    display: flex;
    background: linear-gradient(230.6deg, #FF76A5 13.56%, #FE8C5A 87.82%);
    mix-blend-mode: normal;
    width: 290px;
    height: 290px;
    border-radius: 50%;
    justify-content: center;
}

.DemographicsGradient,
.DemographicsChange {
    display: flex;
    background: linear-gradient(180deg, #FF76A5 0%, #C067E2 100%);
    mix-blend-mode: normal;
    width: 290px;
    height: 290px;
    border-radius: 50%;
    justify-content: center;
}

.HighSchoolGradient,
.HighSchoolChange {
    display: flex;
    background: linear-gradient(122.18deg, #C067E2 13.81%, #749BFF 85.95%);
    mix-blend-mode: normal;
    width: 290px;
    height: 290px;
    border-radius: 50%;
    justify-content: center;
}

.LifeStyleGradient,
.LifeStyleChange {
    display: flex;
    background: linear-gradient(116.42deg, #749BFF 22.88%, #FFCD20 91.67%);
    mix-blend-mode: normal;
    width: 290px;
    height: 290px;
    border-radius: 50%;
    justify-content: center;
}

.BlackCircle {
    display: flex;
    background-color: var(--bg-colour);
    //make small changes depending on media size
    width: 260px;
    height: 260px;
    border-radius: 50%;
    margin-top: 2vh;
    transition: 2s;
}

.TitleText {
    font-size: 22px;
}

.Text {
    font-size: 24px;
    font-family: 'Lexend';
    font-style: normal;
    font-weight: 600;
    align-self: center;
    color: var(--main-text);
    text-align: center;
    margin: 0 auto;

}

section:first-child {
    margin-top: 9%;
    z-index: 0;
    margin-right: -5%;
}

section:nth-of-type(2) {
    margin-top: 6%;
    z-index: 5;
    margin-right: -5%;
}

section:nth-of-type(3) {
    margin-top: 3%;
    z-index: 10;
}

section:nth-of-type(4) {
    margin-top: 6%;
    z-index: 5;
    margin-left: -5%;
}

section:last-child {
    margin-top: 9%;
    z-index: 0;
    margin-left: -5%;
}

.Blurb {
    font-style: normal;
    font-weight: 400;
    font-size: 17px;
    line-height: 22px;
    margin-left: 19% !important;
    margin-right: 19% !important;
}

.DemographicsGradient .Blurb,
.DemographicsChange .Blurb {
    margin-left: 16% !important;
    margin-right: 16% !important;
}

div[class*="Change"] {
    transform: scale(1.2);
    transition: 2s;
}

.BottomCircle {
    display: flex;
    margin-left: -27.5vw;
    width: 155vw;
    height: 175vh;
    background-color: #fff;
    border-radius: 50%;
    overflow: hidden;
    justify-content: center;
}

h3 {
    margin-bottom: -1.5vh;
    margin-top: -1.3vh;
}

.DescriptionTextWrapper {
    text-align: center;
    color: #27313B;
    margin-top: 10vh;
    width: 22vw;
}

.DescriptionPText {
    color: #27313B;
    font-size: 18px;
}

.DescriptionPText:hover {
    color: #FFCD20;
}

.buttonSlider, .phoneButtonSlider, .PhoneWrapper {
    display: none;
}

@media screen and (max-height: 650px) and (min-width: 1000px) {
    .DescriptionTextWrapper {
        width: 50vw;
        margin-top: 5.5vh;
    }   

    .DescriptionWrapper {
        margin-top: -5vh;
    }
    
    .Wrapper {
        margin-top: -7vh;
    }
}

@media screen and (min-height: 651px) and (max-height: 750px) and (min-width: 1000px) {
    .DescriptionTextWrapper {
        width: 50vw;
        margin-top: 7vh;
    }    
    .DescriptionWrapper {
        margin-top: -4vh;
    }
    
    .Wrapper {
        margin-top: -4vh;
    }
}

@media screen and (min-height: 751px) and (max-height: 800px) and (min-width: 1000px) {
    .DescriptionTextWrapper {
        width: 50vw;
    }    
}

@media screen and (max-width: 375px) {
    .InstructionWrapper {
        margin-bottom: 0;
    }

    .Wrapper {
        display: none;
    }

    .PhoneWrapper {
        display: flex;
        justify-content: center;
        opacity: 1;
    }

    .notVisible {
        display: none;
    }

     .BlackCircle {
        margin-top: 4.3vh;
        height: 80%;
        width: 80%;
    }

    .phoneButtonSlider {
        display: flex;
        justify-content: center;
        margin-top: -5vh;
    }

    .btn-slide {
        margin-top: -9vh;
    }

    .container-dots2 {
        display: flex;
        align-self: center;
        padding-top: 7.5vh;
    }

    .BottomCircle {
        margin-top: 0vh;
        height: 60vh;
    }

    .DescriptionTextWrapper {
        width: 80vw;
        margin-top: 7vh;
        font-size: 14px;
    }

    .DescriptionPText {
        font-size: 14px;
    }
}

@media screen and (min-width: 376px) and (max-width: 540px) {

    .notVisible {
        display: none;
    }

    .InstructionWrapper {
        margin-bottom: 6vh;
    }

    .Wrapper {
        display: none;
    }

    .PhoneWrapper {
        display: flex;
        justify-content: center;
        opacity: 1;
    }

    .btn-slide {
        margin-top: 15vh;
    }

    .BlackCircle {
        margin-top: 1.6vh;
    }

    .BottomCircle {
        margin-top: 4vh;
        height: 70vh;
    }

    .phoneButtonSlider {
        display: flex;
        justify-content: center;
    }

    .container-dots2 {
        display: flex;
        align-self: center;
        padding-top: 9.5vh;
    }

    .BottomCircle {
        margin-top: 55vh;
        height: 60vh;
    }

    .DescriptionWrapper {
        margin-top: -50vh;
    }

    .DescriptionTextWrapper {
        width: 70vw;
        margin-top: 6vh;
        font-size: 14px;
    }

    .DescriptionPText {
        font-size: 0.85rem;
    }

    .SmallParagraph1 {
        margin-top: 2vh;
        margin-left: -15vw;
        font-size: 0.85rem;
        width: 100vw;
    }

    .SmallParagraph2 {
        margin-top: -1vh;
        margin-left: -15vw;
        font-size: 0.85rem;
        width: 100vw;
    }
}

@media screen and (min-width: 541px) and (max-width: 768px) {
    .UltimateWrapper {
        max-height: 90vh;
    }

    .InstructionWrapper {
        margin-bottom: 5vh;
    }

    .PhoneWrapper {
        display: none;
    }

    .buttonSlider {
        display: flex;
        justify-content: center;
    }

    .btn-slide {
        margin-top: 15vh;
    }

    .container-dots {
        margin-bottom: 32.5vh;
    }

    .Wrapper {
        display: flex;
        flex-direction: row;
        padding-left: 78vw;
        transform: scale(0.9)
    }
    section:first-child {
        margin-left: 0vw;
        margin-top: 6vh;
        margin-right: -6vw;
    }

    section:nth-of-type(2) {
        z-index: 10; 
        margin-right: 0;
    }

    section:nth-of-type(3) {
        margin-top: 6vh;
        margin-left: -6vw;
        z-index: 5;
    }

    section:nth-of-type(4) {
        padding-left: 20vw;
        margin-top: 3vh;
    }

    section:last-child {
        padding-left: 28vw;
        margin-top: 3vh;
        z-index: 10;
    }

    .Translate1 {
        transform: translateX(-113vw)
    }

    .BlackCircle {
        margin-top: 1.5vh;
    }

    .BottomCircle {
        margin-top: 12vh;
        height: 80vh;
    }

    h3 {
        font-size: 30px;
        margin-bottom: -1vh;
    }

    .DescriptionTextWrapper {
        width: 51vw;
        margin-top: 8vh;
        font-size: 18px;
    }
}

@media screen and (min-width: 769px) and (max-width: 911px) {
    
    .UltimateWrapper {
        max-height: 90vh;
    }

    .InstructionWrapper {
        margin-bottom: 8vh;
    }

    .PhoneWrapper {
        display: none;
    }

    .buttonSlider {
        display: flex;
        justify-content: center;
    }

    .btn-slide {
        margin-top: 10vh;
    }

    .container-dots {
        margin-bottom: 37.5vh;
    }

    .Wrapper {
        padding-left: 72vw;
    }
    section:first-child {
        margin-left: 0vw;
        margin-top: 6vh;
        margin-right: -6vw;
    }

    section:nth-of-type(2) {
        z-index: 10; 
        margin-right: 0;
    }

    section:nth-of-type(3) {
        margin-top: 6vh;
        margin-left: -6vw;
        z-index: 5;
    }

    section:nth-of-type(4) {
        padding-left: 20vw;
        margin-top: 3vh;
    }

    section:last-child {
        padding-left: 28vw;
        margin-top: 3vh;
        z-index: 10;
    }

    .Translate1 {
        transform: translateX(-106vw)
    }

    .BlackCircle {
        margin-top: 1.3vh;
    }

    .BottomCircle {
        margin-top: 20vh;
        height: 80vh;
    }

    h3 {
        font-size: 30px;
        margin-bottom: -1vh;
    }

    .DescriptionTextWrapper {
        width: 51vw;
        margin-top: 8vh;
        font-size: 18px;
    }
}

@media screen and (min-width: 912px) and (max-width: 1000px) {

    .UltimateWrapper {
        max-height: 90vh;
    }

    .InstructionWrapper {
        margin-bottom: 8vh;
    }

    .PhoneWrapper {
        display: none;
    }

    .buttonSlider {
        display: flex;
        justify-content: center;
    }

    .btn-slide {
        margin-top: 10vh;
    }

    .container-dots {
        margin-bottom: 37.5vh;
    }

    .Wrapper {
        display: flex;
        flex-direction: row;
        padding-left: 63vw;
    }
    section:first-child {
        margin-left: 0vw;
        margin-top: 6vh;
        margin-right: -6vw;
    }

    section:nth-of-type(2) {
        z-index: 10; 
        margin-right: 0;
    }

    section:nth-of-type(3) {
        margin-top: 6vh;
        margin-left: -6vw;
        z-index: 5;
    }

    section:nth-of-type(4) {
        padding-left: 20vw;
        margin-top: 3vh;
    }

    section:last-child {
        padding-left: 28vw;
        margin-top: 3vh;
        z-index: 10;
    }

    .Translate1 {
        transform: translateX(-97vw)
    }

    .BlackCircle {
        margin-top: 1.5vh;
    }

    .BottomCircle {
        margin-top: 20vh;
        height: 80vh;
    }

    h3 {
        font-size: 30px;
        margin-bottom: -1vh;
    }

    .DescriptionTextWrapper {
        width: 51vw;
        margin-top: 8vh;
        font-size: 18px;
    }
}

@media screen and (min-width: 1920px) {

    .BlackCircle {
        margin-top: 1.5vh;
    }

    section:first-child {
        margin-right: -3.5%;
    }

    section:nth-of-type(2) {
        margin-right: -3.5%;
    }

    section:nth-of-type(4) {
        margin-left: -3.5%;
    }

    section:last-child {
        margin-left: -3.5%;
    }

    h3 {
        margin-bottom: -1.5vh;
    }

    .BottomCircle {
        margin-top: 3vh;
        height: 200vh;
    }

    .DescriptionTextWrapper {
        width: 20vw;
        margin-top: 10vh;
    }
}

@media screen and (min-width: 2500px) {

    .InstructionWrapper {
        margin-bottom: 5vh;
    }

    .BlackCircle {
        margin-top: 1.3vh;
    }

    section:first-child {
        margin-right: -3.5%;
    }

    section:nth-of-type(2) {
        margin-right: -3.5%;
    }

    section:nth-of-type(4) {
        margin-left: -3.5%;
    }

    section:last-child {
        margin-left: -3.5%;
    }

    h3 {
        margin-bottom: -1.5vh;
    }

    .BottomCircle {
        margin-top: 10vh;
        height: 200vh;
    }

    .DescriptionTextWrapper {
        width: 18vw;
        margin-top: 12vh;
    }
}