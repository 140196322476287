@import url('https://fonts.googleapis.com/css2?family=Lexend&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Lexend&family=Montserrat&display=swap');

.ButtonWrapper {
  border: 0px hidden transparent;
  border-radius: 20px;
  padding: 3px; //keep as px
  margin: 0px;
}

.NavigationalButtons {
  /*FlexBox*/
  display: flex;
  flex-flow: row nowrap;
  justify-content: space-between;
  margin: 0px 15% 6.25rem 15%
}

#coop {
  background-image: linear-gradient(242.5deg, #FE8C5A 25.46%, #F7BF00 89.55%);
  background-color: #C067E2;
}

#academics {
  background-image: linear-gradient(230.6deg, #FF76A5 13.56%, #FE8C5A 87.82%);
  background-color: #C067E2;
}

#demographics {
  background-image: linear-gradient(180deg, #FF76A5 0%, #C067E2 100%);
  background-color: #C067E2;
}

#highschool {
  background-image: linear-gradient(122.18deg, #C067E2 13.81%, #749BFF 85.95%);
  background-color: #C067E2;
}

#lifestyles {
  background-image: linear-gradient(116.42deg, #749BFF 22.88%, #FFCD20 91.67%);
}

@media screen and (max-width: 1600px) {


  .NavigationalButtons {
    margin: 0px 15% 6.25rem 15%
  }
}

@media screen and (max-width: 1400px) {

  .NavigationalButtons {
    margin: 0px 15% 6.25rem 15%
  }
}

@media screen and (max-width: 1200px) {

  .NavigationalButtons {
    margin: 0px 15% 6.25rem 15%
  }

}

@media screen and (max-width: 960px) {

  .NavigationalButtons {
    margin: 0px 10% 6.25rem 10%
  }

}

@media screen and (max-width: 480px) {

  .NavigationalButtons {
    margin: 0px 10% 6.25rem 10%
  }

}

@media screen and (max-width: 360px) {

  .NavigationalButtons {
    margin: 0px 10% 6.25rem 10%
  }
}