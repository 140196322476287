.about {
    margin-top: 4.45em;
}
.classPic {
    display: block;
    margin-left: auto;
    margin-right: auto;
    width: 65%;
}

.heading {
    color: #fff;
    margin-top: 1.65em;
    margin-bottom: 1em;
}

.descriptions {
    display: flex;
    width: 65%;
    margin-left: auto;
    margin-right: auto;
}

.descriptions:after {
    content: "";
    display: table;
    clear: both;
}

.column {
    float: left;
    width: 50%;
}

.textBox {
    width: 100%;
    margin-top: 1em;
    font-style: normal;
    font-weight: 400;
    font-size: 17px;
    line-height: 23px;
}

.two {
    margin-left: 5.75%;
}

.credit {
    display: block;
    margin-top: 2.25em;
    margin-left: 17.5%;
    margin-right: 10%;
}

.credit > h3 {
    color: #fff;
    margin-bottom: 0;
}

.design, .data, .software {
    margin-bottom: 2.5em;
}

.design {
    margin-top: 1.4em;
}

.team {
    display: inline;
    font-style: normal;
    font-weight: 400;
    font-size: 17px;
    line-height: 23px;
}

.design > .team {
    margin-right: 16px;
}

.data > .team {
    margin-right: 33px;
}

.avatarRow {
    display: inline;
    vertical-align: middle;
    margin-left: 2em;
}

.links {
    color: white;
    display: block;
    width: 65%;
    margin-left: auto;
    margin-right: auto;
    margin-bottom: 5.5em;
    padding-top: 0.5em;
}

.links>h3 {
    color: #fff;
    margin-bottom: 1em;
    margin-top: 0;
}

.github {
    display: inline;
    margin-right: 3.8em;
}

.report {
    display: inline;
}

.icon {
    height: 25px;
    width: auto;
    margin-right: 0.9em;
}

.linkText {
    text-decoration: underline;
    display: inline;
    white-space: nowrap;
    font-style: normal;
    font-weight: 400;
    font-size: 17px; 
    line-height: 22px;
}

@media screen and (max-width: 1340px) {
    .credit {
        margin-top: 2em;
        margin-left: 17.5%;
    }

    .design, .data, .software {
        margin-bottom: 1em;
    }

    .team {
        display: block;
    }

    .avatarRow {
        margin-left: 0;
    }
}

@media screen and (max-width: 960px) {
    .classPic {
        margin-top: 0;
    }

    .descriptions {
        display: block;
    }

    .column {
        width: 100%;
    }

    .two {
        margin-left: 0;
    }

    .heading {
        margin-top: 1em;
        margin-bottom: 1em;
    }

    .textBox {
        margin-top: 0.75em;
    }
}

@media screen and (max-width: 900px) {
    .github {
        display: block;
        margin-bottom: 1em;
    }

    .linkText {
        margin-left: 0.9em;
    }

    .github > .icon {
        margin-right: 0;
    }

    .report > .icon {
        margin-right: 5px;
    }
}

@media screen and (max-width: 600px) {
   .links {
        margin-bottom: 3em;
    }
}

@media screen and (max-width: 375px) {
    .linkText {
        font-size: 15px;
    }
    
    .icon {
        height: 18px;
        width: auto;
    }
}

@media screen and (max-width: 350px) {
    .linkText {
        font-size: 12px;
    }

    .icon {
        height: 14px;
        width: auto;
    }

}