.tileBox {
  display: flex;
  flex-flow: row wrap;
  justify-content: space-between;
  column-gap: 1rem;
  margin: 0 15% 64px 15%;
}

.flexTiles {
  min-width: 30%;
  display: inline-block;
  flex: 1 1 auto;
  background-image: linear-gradient(242.5deg, rgba(254, 140, 90, 0.3) 25.46%, rgba(247, 191, 0, 0.3) 89.55%);
  border: 0 solid transparent;
  border-radius: 1.25rem;
  padding: 1.5625rem;
  margin: 0 auto 1rem auto;
  text-align: center;
}

@media screen and (max-width: 1600px) {
  .tileBox {
    margin: 0 15% 4rem 15%;
  }

}

@media screen and (max-width: 1400px) {
  .tileBox {
    margin: 0 15% 4rem 15%;
  }
}

@media screen and (max-width: 1200px) {
  .tileBox {
    margin: 0 15% 4rem 15%;
  }
}

@media screen and (max-width: 960px) {
  .tileBox {
    margin: 0 10% 4rem 10%;
  }
}

@media screen and (max-width: 480px) {
  .tileBox {
    margin: 0 10% 4rem 10%;
  }
}

@media screen and (max-width: 360px) {
  .tileBox {
    margin: 0 10% 4rem 10%;
  }
}