@import 'styles/globals.scss';

.overlay {
    position: fixed;
    left: 0;
    top: 0;
    right: 0;
    bottom: 0;
    background-color: transparent;
    display: flex;
    align-items: center;
    justify-content: center;
    backdrop-filter: blur(200px);
    -webkit-backdrop-filter: blur(200px);
    @-moz-document url-prefix() {
        background-color: #27313B;
    }
    z-index: 20;
}

.overlay-content {
    height: 100%;
    width: 100%;
    display: flex;
    flex-direction: column;
    flex: 1;
}

.close-section {
    display: flex;
    height: 10%;
    width: 100%;
    padding: none;
}

.close-button {
    border: none;
    background: none;
    margin-top: 2em;
    margin-left: auto;
    margin-right: 40px;
    margin-bottom: auto;
    max-height: 40px;
    max-width: 40px;
    padding: none;
}

.close-button:hover {
    opacity: 0.5;
}

.close-icon {
    padding: auto;
    margin: auto;
}

@media not all and (min-resolution:.001dpcm) { 
    @supports (-webkit-appearance:none) {
        .close-icon { 
            transform: scale(0.8,0.8); 
        }
    }
}

.profile-button {
    height: 20vh;
    width: 100%;
    border: none;
    background: none;
    display: flex;
    align-items: center;

    .number-text {
        color: white;
        font-weight: 300;
        margin-left: 5%;
        margin-top: 3vh;
        margin-bottom: min(60px,8%);
    }

    .page-text {
        color: white;
        font-size: 3.5vmax;
        font-weight: 300;
        margin-bottom: max(30px,4%);
        margin-left: max(40px,2.5%);
        margin-right: auto;
        line-height: 0;
    }
}

.profile-button:hover {
    opacity: 0.5;
}

.profile-subsection {
    border: none;
    display: flex;
    flex-direction: column;
    background: none;
    align-items: center;
    height: 10%;
    width: 100%;
    margin-top: 0;
    margin-bottom: 1.5%;
}

.profile-flexbox {
    display: flex;
    flex-direction: row;
    width: 100%;
    justify-content: space-evenly;
    align-items: center;
    flex-wrap: wrap;
    padding-left: 0;
    padding-right: 0;
}

.demographics-button {
    background: none;
    border: none;
    margin-top: 0;
    margin-bottom: auto;

    .section-text {
        color: white;
        font-weight: 100;
        font-size: 1.75vmax;
        text-align: center;
        margin-top: 0;
        margin-bottom: 3.5vh;
        line-height: 25px;
    }
}

.demographics-button:hover {
    opacity: 0.5;
}


.hs-button {
    background: none;
    border: none;
    margin-top: 0;
    margin-bottom: auto;

    .section-text {
        color: white;
        font-weight: 100;
        font-size: 1.75vmax;
        text-align: center;
        margin-top: 0;
        margin-bottom: 3.5vh;
        line-height: 25px;
    }
}

.hs-button:hover {
    opacity: 0.5;
}


.academics-button {
    background: none;
    border: none;
    margin-top: 0;
    margin-bottom: auto;

    .section-text {
        color: white;
        font-weight: 100;
        font-size: 1.75vmax;
        text-align: center;
        margin-top: 0;
        margin-bottom: 3.5vh;
        line-height: 25px;
    }
}

.academics-button:hover {
    opacity: 0.5;
}

.coop-button {
    background: none;
    border: none;
    margin-top: 0;
    margin-bottom: auto;
    margin-left: 0;
    

    .section-text {
        color: white;
        font-weight: 100;
        font-size: 1.75vmax;
        text-align: center;
        margin-top: 0;
        margin-bottom: 3.5vh;
        line-height: 25px;
    }
}

.coop-button:hover {
    opacity: 0.5;
}

.lifestyle-button {
    background: none;
    border: none;
    margin-top: 0;
    margin-bottom: auto;

    .section-text {
        color: white;
        font-weight: 100;
        font-size: 1.75vmax;
        text-align: center;
        margin-top: 0;
        margin-bottom: 3.5vh;
        line-height: 25px;
    }
}

.lifestyle-button:hover {
    opacity: 0.5;
}

.about-button {
    height: 20vh;
    width: 100%;
    border: none;
    background: none;
    display: flex;
    align-items: center;

    .number-text {
        color: white;
        font-weight: 300;
        margin-left: 5%;
        margin-top: 3vh;
        margin-bottom: auto;
    }

    .page-text {
        color: white;
        font-size: 3.5vmax;
        font-weight: 300;
        margin-left: max(40px,2.5%);
        margin-right: auto;
        margin-top: 1vh;
    }
}

.about-button:hover {
    opacity: 0.5;
}

.photo-gallery-button {
    height: 20vh;
    width: 100%;
    border: none;
    background: none;
    display: flex;
    align-items: center;

    .number-text {
        color: white;
        font-weight: 300;
        margin-left: 5%;
        margin-top: 3vh;
        margin-bottom: auto;
    }

    .page-text {
        color: white;
        font-size: 3.5vmax;
        font-weight: 300;
        margin-left: max(40px,2.5%);
        margin-right: auto;
        flex-shrink: 1;
        margin-top: 1vh;
    }
}

.photo-gallery-button:hover {
    opacity: 0.5;
}

.meme-page-button {
    height: 20vh;
    width: 100%;
    border: none;
    background: none;
    display: flex;
    align-items: center;

    .number-text {
        color: white;
        font-weight: 300;
        margin-left: 5%;
        margin-top: 3vh;
        margin-bottom: auto;
    }

    .page-text {
        color: white;
        font-size: 3.5vmax;
        font-weight: 300;
        margin-left: max(40px,2.5%);
        margin-right: min(25px, 5%);
        margin-top: 1vh;
    }

    .lock-icon {
        margin-right: auto;
        margin-top: -6.3vh;
        margin-bottom: -1.4vh;
        max-width: 7vw;
        max-height: 7vh;
    }
}

.meme-page-button:hover {
    opacity: 0.5;
}

a{
    text-decoration: none !important;
}

@media screen and (max-width: 600px){
    .profile-flexbox {
        display: flex;
        flex-direction: row;
        width: 65%;
        margin-top: -1rem;
        margin-left: 2.5rem;
        justify-content: space-between;
    }
    // .close-button{
    //     margin-left: -200%;
    // }
    // .section-text{
    //     color: black;
    // }
}

@supports (-webkit-touch-callout: none) {
    .close-icon {
        margin-left: -70% !important;
        transform: scale(0.8,0.8);
    }
}

  
@media screen and (max-width: 600px){
    @supports not (-webkit-touch-callout: none) {
        .overlay{
            background-color: #27313B;
        }
    }
}